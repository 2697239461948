<template>
  <div class="user-page container-fluid h-100">

    <div class="d-flex flex-column justify-content-between h-100">
      <div>

        <div class="h-top">
          <router-link :to="{path: '/'}" class="button-back-empty">
            <span class="fa fa-arrow-left"></span>
          </router-link>
        </div>

        <div class="h-title">
          LOG IN WITH EMAIL
        </div>

        <form class="col-md-4 offset-md-4 h-form" @submit.prevent="doUserLogin">
          <div class="form-group mb-3">
            <input
                v-model="login"
                :class="{'is-invalid': errors?.login}"
                type="text"
                class="form-control rounded-4"
                id="login-input"
                placeholder="Enter email as login" />
            <div class="invalid-feedback">{{ errors?.login }}</div>
          </div>

          <div class="form-group mb-3">
            <input
                v-model="password"
                :class="{'is-invalid': errors?.password}"
                type="password"
                class="form-control rounded-4"
                id="password-input"
                placeholder="Password" />
            <div class="invalid-feedback">{{ errors?.password }}</div>
          </div>

          <button class="btn btn-form mt-2" type="submit">Login</button>

          <div class="h-subtext">
            <router-link to="/user/recover">Forgot password?</router-link>
          </div>

        </form>
      </div>
      <div class="h-bottom">
        NEED A NEW ACCOUNT?
        <router-link to="/user/register">Sign up</router-link>
      </div>
    </div>

  </div>
</template>

<script>
import UserMixin from '@/modules/user/mixins/UserMixin';

export default {

  mixins: [UserMixin],

  data: () => ({
    login: null,
    password: null,
  }),

  mounted() {

    if (this.isUser())
      return this.redirect('/place/map');
  },

  methods: {

    async doUserLogin() {

      let apiHolder = await this.sendApiPost('api/user/login', {
        login: this.login,
        password: this.password
      });

      if (!apiHolder.isSuccess() || !apiHolder.data)
        return;

      const {accessToken, refreshToken} = apiHolder.data;
      this.saveUserAuth(accessToken, refreshToken);

      const backRoute = this.loadBackRoute();
      await this.redirect(backRoute ? backRoute : '/place/map');
    }

  }

}
</script>

<style scoped>

.h-title {
  padding-top: 80px;
}

</style>